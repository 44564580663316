var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-approval-by-me"},[_c('h2',[_vm._v("待审批列表")]),_vm._l((_vm.approvalList),function(item){return _c('van-cell-group',{key:item.id,staticClass:"apply-list",attrs:{"inset":""}},[_c('van-cell',{attrs:{"size":"large"}},[_c('b',[_vm._v(_vm._s(item.name || "待审核任务")+":"+_vm._s(item.formKeyDesc))])]),_c('van-cell',{attrs:{"size":"large","title":"申请者"}},[_vm._v(" "+_vm._s(item.assignee || "岗位审核")+" ")]),_c('van-cell',{attrs:{"size":"large","title":"创建时间"}},[_vm._v(" "+_vm._s(_vm._f("date")(item.created))+" ")]),_c('van-cell',{attrs:{"size":"large","center":""}},[[_c('div',{staticStyle:{"text-align":"center"}},[_c('van-button',{staticStyle:{"width":"3rem"},attrs:{"size":"small","round":"","type":"info"},on:{"click":function($event){return _vm.newBpmnApproval({
                title: '审核',
                formKeyName: item.formKeyName,
                formKeyDesc: item.formKeyDesc,
                args: { name: '审核' },
                query: {
                  processInstanceId: item.processInstanceId,
                  formKey: item.formKey,
                  taskId: item.id
                }
              })}}},[_vm._v(" 审批 ")]),_c('van-button',{staticStyle:{"width":"3rem","margin-left":"0.5rem"},attrs:{"plain":"","size":"small","round":"","type":"info"},on:{"click":function($event){return _vm.showBpmnInstanceDiagram(item)}}},[_vm._v(" 查看 ")])],1)]],2)],1)}),(!_vm.approvalList.length)?_c('van-empty',{attrs:{"description":"暂无审批"}}):_vm._e(),_c('bpmnInstanceDiagram',{ref:"bpmnInstanceDiagram"})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }