<template>
  <div class="page-approval-by-me">
    <h2>待审批列表</h2>
    <van-cell-group class="apply-list"
                    inset
                    v-for="item in approvalList"
                    :key="item.id">
      <van-cell size="large">
        <b>{{ item.name || "待审核任务" }}:{{ item.formKeyDesc }}</b>
      </van-cell>

      <van-cell size="large"
                title="申请者">
        {{ item.assignee || "岗位审核" }}
      </van-cell>

      <van-cell size="large"
                title="创建时间">
        {{ item.created | date }}
      </van-cell>

      <van-cell size="large"
                center>
        <template>
          <div style="text-align: center;">

            <van-button style="width:3rem;"
                        size="small"
                        round
                        type="info"
                        @click="
                newBpmnApproval({
                  title: '审核',
                  formKeyName: item.formKeyName,
                  formKeyDesc: item.formKeyDesc,
                  args: { name: '审核' },
                  query: {
                    processInstanceId: item.processInstanceId,
                    formKey: item.formKey,
                    taskId: item.id
                  }
                })
              ">
              审批
            </van-button>
            <van-button style="width:3rem;margin-left:0.5rem;"
                        plain
                        size="small"
                        round
                        type="info"
                        @click="showBpmnInstanceDiagram(item)">
              查看
            </van-button>
          </div>
        </template>
      </van-cell>
    </van-cell-group>
    <van-empty v-if="!approvalList.length"
               description="暂无审批" />

    <bpmnInstanceDiagram ref="bpmnInstanceDiagram"></bpmnInstanceDiagram>
  </div>
</template>
<script>
import "@/assets/less/views/approval/approvalByMe.less";
import { SUCCESS_CODE } from "@/apis/notification.js";
import { sortBy } from "lodash-es";
import bpmnInstanceDiagram from "./bpmnInstanceDiagram.vue";
import { mapState } from "vuex";
export default {
  data() {
    return {
      processKeyList: [],

      approvalList: [],
      formList: []
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  methods: {
    async init() {
      this.approvalList = [];
      await this.getFormList();
      await this.getTaskByAssignee();
      // 从推送消息调审批
      if (this.$route.query.approvalTaskId) {
        this.redirectInspectDetail(this.$route.query.approvalTaskId);
      }
    },

    async getTaskByAssignee() {
      let ret = await this.$api.getTaskByPosition({
        params: {
          organizationId: this.$keycloak.tokenParsed.organization,
          groups: this.$keycloak.tokenParsed.groups.map(item => item.slice(1)).toString()
        }
      });
      if (ret.code != SUCCESS_CODE) {
        ret.data = [];
      }

      let data = ret.data;
      data.forEach(item => {
        item.created = this.$moment(item.created).format("YYYY-MM-DD HH:mm");
        let formKeyIdx = this.formList.findIndex(it => {
          return item.formKey == it.id;
        });
        if (formKeyIdx !== -1) {
          item.formKeyName = this.formList[formKeyIdx].name;
          item.formKeyDesc = this.formList[formKeyIdx].description;
        }
      });

      this.approvalList = sortBy(data, ["created"]).reverse();
    },
    async getProcessInstanceVaribles(processInstanceId) {
      if (!processInstanceId) return {};
      let ret = await this.$api.getProcessInstanceVaribles({
        placeholder: { id: processInstanceId }
      });
      if (ret.code != SUCCESS_CODE) return {};
      let data = {};
      Object.keys(ret.data).forEach(item => {
        data[item] = ret.data[item].value;
      });
      return data;
    },
    async getFormList() {
      let ret = await this.$api.getFormList({ async: false });
      if (ret.code != SUCCESS_CODE) return;
      this.formList = ret.data;
    },
    async newBpmnApproval(to) {
      var url;
      if (!to.args) to.args = {};

      if (to.query.processKey) {
        url = this.$utils.getFormUrlByKey(to.query.formKey, to.query.formKey == "classroomReserve" ? { classroomId: 1 } : {});
      } else {
        let ret = await this.$api.getProcessInstanceVaribles({ placeholder: { id: to.query.processInstanceId } });
        if (ret.code != SUCCESS_CODE) return;

        delete to.query.formKey;
        ret.data.query.value.renderFormKey = ret.data.query.value.renderFormKey.toString();
        url = this.$utils.getFormUrlByKey(to.formKeyName, {
          ...ret.data.query.value,
          ...to.query,
          readOnly: ""
        });
      }
      location.href = url;
    },
    showBpmnInstanceDiagram(row) {
      row.rootProcessInstanceId = row.processInstanceId;
      this.$refs["bpmnInstanceDiagram"].viewDetail({ ...row, state: "ACTIVE" });
    },
    redirectInspectDetail(taskId) {
      if (!taskId) return;
      let item = this.approvalList.find(item => {
        return item.id == taskId;
      });
      if (!item) return;

      this.newBpmnApproval({
        title: '审核',
        formKeyName: item.formKeyName,
        formKeyDesc: item.formKeyDesc,
        args: { name: '审核' },
        query: {
          processInstanceId: item.processInstanceId,
          formKey: item.formKey,
          taskId: item.id
        }
      });
    }
  },
  components: {
    bpmnInstanceDiagram
  }
};
</script>
